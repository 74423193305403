<template>
  <div>
    <div v-if="isLoading" class="loading">
      <div class="spinner"></div>
    </div>
    <div v-else class="case">
      <h4 class="mb-3">{{ t('choiceThemeColor') }}</h4>
      <table>
        <tr class="mb-3">
          <th>{{ t('name') }}</th>
          <th>{{ t('primaryColor') }}</th>
          <th>{{ t('secondaryColor') }}</th>
        </tr>
        <tr
          v-for="item in palettes"
          :key="item"
          :class="{
            active: item.selected,
            localSelected: item.clicked,
          }"
          class="option"
          @click="setColor(item)"
        >
          <td class="name">
            {{ item.name }}
          </td>
          <td>
            <span
              class="circle"
              :style="{ backgroundColor: item.primary_color }"
            ></span>
            <span>{{ item.primary_color }}</span>
          </td>
          <td>
            <span
              class="circle"
              :style="{ backgroundColor: item.secondary_color }"
            ></span>
            <span>{{ item.secondary_color }}</span>
          </td>

          <td>
            <span v-if="item.selected" class="selected">{{ t('onLive') }}</span>
          </td>
        </tr>
      </table>
      <div class="container mt-2">
        <div class="row">
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a @click="cancel" class="btn btn-danger">{{ t('cancel') }}</a>
            </div>
          </div>
          <div class="col-lg-6 mt-3">
            <div class="input-wrap">
              <a @click="onSubmit" class="btn btn-success">{{ t('save') }}</a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import useGlobal from '../../composables/useGlobal'
import { ref, onMounted } from 'vue'

export default {
  setup() {
    const { t, restApi, successNotify, errorNotify, emitter, router } =
      useGlobal()

    const isLoading = ref(false)

    const palettes = ref([])

    const selectedColor = ref({})

    const setColor = (color) => {
      emitter.emit('set-color', color)
      selectedColor.value = color
      color.clicked = true

      palettes.value.forEach((item) => {
        if (item.name !== color.name) {
          item.clicked = false
        }
      })
    }

    const fecthColors = () => {
      palettes.value = []
      isLoading.value = true
      restApi.getColorSets().then((res) => {
        res.getColors().forEach((item) => {
          palettes.value.push({
            name: item.getName(),
            primary_color: item.getPrimaryColor(),
            secondary_color: item.getSecondaryColor(),
            // text_color: item.getTextColor(),
            selected: item.getSelected(),
            clicked: false,
          })
        })
        isLoading.value = false
      }).catch(() => {
        isLoading.value = false
        errorNotify(
          'Failed to get color sets.',
          'Please check your network connection.',
        )
      })
    }

    onMounted(() => {
      fecthColors()
    })

    const onSubmit = () => {
      isLoading.value = true
      restApi.setColorSet(selectedColor.value.name).then(() => {
        isLoading.value = false
        emitter.emit('refresh-simulation')
        fecthColors()
        successNotify(
          'Successfully changed color set.',
          'You can change color set in the future.',
        )
      }).catch(() => {
        isLoading.value = false
        errorNotify(
          'Failed to set color sets.',
          'Please check your network connection.',
        )
      })
    }

    const cancel = () => {
      emitter.emit('refresh-simulation')
      router.push({ name: 'StoreManagement.Page' })
    }

    return {
      t,
      palettes,
      setColor,
      isLoading,
      onSubmit,
      cancel,
    }
  },
}
</script>

<style scoped>
table {
  width: 100%;
}

th {
  padding: 0 0 1em 2em;
}

td {
  padding: 20px 10px;
  vertical-align: center;
  cursor: pointer;
}

.option:hover .name {
  background: lightgray;
}

.option .circle {
  width: 30px;
  height: 30px;
  display: inline-block;
  margin: 0 10px;
  border-radius: 50%;
}

.active {
  position: relative;
}

.selected {
  border: 1px solid gray;
  padding: 0.2em 0.5em;
}

.localSelected {
  background-color: #f4f4f4;
  position: relative;
}

.localSelected::after {
  content: '';
  position: absolute;
  top: 40%;
  left: 99.2%;
  width: 0;
  height: 0;
  transform: rotate(90deg);
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  border-bottom: 10px solid #f4f4f4;
}
</style>
